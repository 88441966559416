@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Oswald";

body {
  font-family: "Open Sans", sans-serif;
  background: #ffffff;
}

p {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7em;
  color: rgb(175, 175, 175);
}

h2 {
  /* font-family: "Syncopate", sans-serif; */
  /* font-family: "Open Sans", Arial, sans-serif; */
  font-family: "Oswald";
}

.naslov {
  /* padding-left: 50%; */
  text-align: center;
  font-weight: 2000;
  /* color: #0074d9; */
}

.z naslov {
  /* font-size: 1.4rem; */
  font-size: xx-small;
}

.navbar-brand {
  font-size: 1.1rem;
}

.h2 span {
  color: #0074d9;
}

.header_povezave {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: medium;
  /* margin-right: 40%; */
  padding-right: 100%;
}

.navbar-nav {
  margin-right: 20%;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10%;
  font-size: 1.5em;
  background: #1a73e8;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: none;
  /*box-shadow: 20px 20px 10px grey;*/
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

#headerProfile {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: medium;
  /* color: rgba(255, 255, 255, 0.7); */
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.space {
  margin-left: 2em;
  margin-right: 2em;
}

#head {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.281); */
  font: Helvetica;
  background-image: linear-gradient(rgba(0, 0, 0, 0.253), rgba(0, 0, 0, 0.061));
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.657), transparent); */
  /* background-color: #3a3c40; */
}

#headDown {
  color: black;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  background: rgba(136, 136, 136, 0.109);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #051e34;
  color: #a9b7d0;
  transition: all 0.3s;
  box-shadow: 5px 10px 8px #888888;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #051e34;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #3f4d67;
}

#sidebar ul p {
  color: #a9b7d0;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.3em;
  display: block;
}

#sidebar ul li a:hover {
  cursor: pointer;
  color: #17a2b8;
  background: rgba(71, 98, 130, 0.4);
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #a9b7d0;
  background: #3f4d67;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #39465e;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #39465e;
}

a.article,
a.article:hover {
  background: #3f4d67 !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  /*padding: 20px;*/
  padding: 0px;
  min-height: 100vh;
  transition: all 0.3s;
}

#contentDashboard {
  width: 100%;
  /*padding: 20px;*/
  padding: 0px;
  min-height: 100vh;
  transition: all 0.3s;
  background: linear-gradient(
    to bottom,
    #1a73e8 50%,
    rgba(136, 136, 136, 0.109) 50%
  );
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}

/*--------------------
PHONE DROPDOWN
--------------------*/

#navhead ul li.active > c,
c[aria-expanded="true"] {
  color: #a9b7d0;
  background: #ffffff;
}

c[data-toggle="collapse"] {
  position: relative;
}

c.download {
  background: #fff;
  color: #39465e;
}

c.article,
c.article:hover {
  cursor: pointer;
  background: #3f4d67 !important;
  color: #fff !important;
}

/*----------------------
HOME PAGE
-----------------------*/

.container-fluid {
  /* background-color: black; */
  justify-content: center;
  align-items: center;
  text-align: center;
}

#bluebg {
  /* background-color: #1a73e8; */
  /* background: linear-gradient(80deg, #ff0000 20%, #0000ff 0%); */
  display: block;
  min-height: 100px;
  position: relative;
}

.prva_slika {
  background-image: url("../images/naslovnica6.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100vh; /* You must set a specified height */
  width: 100%;
  /* background-position: center; */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-position: 60%;
  background-attachment: local;
}

.prva_slika_telefon {
  background-image: url("../images/naslovnica_telefon.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 300px; /* You must set a specified height */
  width: 100%;
  /* background-position: center; */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-position: 50% 50%;
  background-attachment: local;
}

/* .blur {
  padding-top: 20%;
  padding-left: 10%;
}

.blur:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.109);
  filter: blur(5px);
  z-index: 0;
}

.blur span {
  position: relative;
  z-index: 1;
} */

.parent {
  width: 50%;
  height: 222px;
  position: relative;
  overflow: hidden;
}
.parent .blur {
  display: block;
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #ccc;
}
.parent h1 {
  position: relative;
  font-size: 25px;
  color: #fff;
  z-index: 5;
  text-align: center;
  line-height: 150px;
}

.fa-stack:hover .fa-circle {
  opacity: 0;
}

.fa-stack:hover .fa-book {
  color: #343a40;
}
.fa-stack:hover .fa-laptop {
  color: #343a40;
}
.fa-stack:hover .fa-heart {
  color: #343a40;
}
.fa-stack:hover .fa-cloud {
  color: #343a40;
}

#thermometer {
  width: 85%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slika_opis {
  width: 896px;
  height: 600px;
}

.partner {
  background-image: url("../images/partner_bg.png");
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 400px; /* You must set a specified height */
  width: 100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

/* .haccp_logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: auto;
} */

.footer {
  background-color: #1b1b1b;
  height: 350px;
}

.copyright {
  float: right;
}

.copyright2 {
  float: left;
}
/*---------------------------------------
CARD
----------------------------------------*/

.card {
  /* box-shadow: 5px 10px 8px #3a3a3a7a; */
  -webkit-box-shadow: 0 0 10px #3a3a3a7a;
  box-shadow: 0 0 10px #3a3a3a7a;
  /* margin-right: 3em;
  margin-left: 15em; */
  margin: auto;
  min-height: 5em;
}

.vl {
  border-left: 1px solid rgba(128, 128, 128, 0.541);
  height: 15em;
  position: relative;
  /*left: 50%;*/
  margin-left: -3px;
  top: 0;
}

.phoneCard {
  box-shadow: 5px 10px 8px #888888;
  /* margin-right: 1em;
  margin-left: 1em;
  min-height: 15em; */
  margin: auto;
  min-height: 15em;
}

#calibrationC {
  margin-left: 1em;
  width: 15em;
  height: 5em;
}

.cart {
  margin-right: 2em;
}

#temperatura {
  font-family: "Oswald";
}

.login_p {
  overflow: auto;
  width: 100%;
  height: 100vh;
  /* background-color: #292b2c; */
  background-image: url("../images/naslovnica6.jpg");
  background-position: 60%; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.card_login {
  background-color: rgba(65, 63, 63, 0.712);
}

/* MASTERPIECE BELOW */
h4.absolute {
  position: relative; /* Helps us control overlap */
  color: rgb(175, 175, 175);
  font-family: "Open Sans", Arial, sans-serif;
  padding-left: 50px; /* Creates space for the Phone Icon */
}

h4.absolute:before {
  content: "\f0e0";
  color: white;
  font-family: fontAwesome;
  position: absolute;
  left: 0; /* Adjust as needed */
  top: 3px; /* Adjust as needed */
}

.crta {
  border-bottom: 1px solid rgb(255, 252, 252);
  /* height: 15em;
  position: relative;
  left: 50%;
  margin-left: -3px; 
  top: 0; */
}
